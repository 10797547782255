.dropdown-container{
    @apply flex lg:hidden w-full flex-col justify-start items-start p-5 font-bold text-[17px] gap-3 
}

.hover-transition{
    @apply hover:text-primary transition duration-300
}

.dropdown-link{
    @apply text-non_selected flex flex-col justify-center items-center gap-0.5 hover-transition
}
